import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Book1 from '@/assets/img/advocacy-for-children/policy_book1_1@2x.png';
import Book2 from '@/assets/img/advocacy-for-children/policy_book1_2@2x.png';
import Book3 from '@/assets/img/advocacy-for-children/policy_book1_3@2x.png';
import Book4 from '@/assets/img/advocacy-for-children/policy_book1_4@2x.png';
import Book5 from '@/assets/img/advocacy-for-children/policy_book1_5.png';
import Book6 from '@/assets/img/advocacy-for-children/policy_book1_6.png';
import Book7 from '@/assets/img/advocacy-for-children/policy_book1_7.png';
import Book20 from '@/assets/img/advocacy-for-children/policy_book2_1@2x.png';
import Book21 from '@/assets/img/advocacy-for-children/policy_book2_2@2x.png';
import KvImg from '@/assets/img/advocacy-for-children/policy_kv.jpg';
import KvImgM from '@/assets/img/advocacy-for-children/policy_kvM@2x.jpg';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import { CardFlex, DlBox } from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { H3, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const bookData1 = [
  {
    id: 0,
    img: Book1,
    tit: `Vol.1 아동친화 소년사법을 위한 제안서`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/732/`,
  },
  {
    id: 1,
    img: Book2,
    tit: `Vol.1 아동친화 소년사법을 위한 제안서(요약본)`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/615/`,
  },
  {
    id: 2,
    img: Book3,
    tit: `Vol.2 아동학대와 온라인 성착취 예방을 위한 제도 개선안`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/for-many-children-violence-wears-a-familiar-face/`,
  },
  {
    id: 3,
    img: Book4,
    tit: `Vol.3 유엔아동권리협약 선택의정서 이행 가이드라인`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/OPSC-guideline/`,
  },
  {
    id: 4,
    img: Book5,
    tit: `Vol.4 아동 피해자와 아동 증인에 관한 사법 절차 가이드라인`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/childvictim-guideline/`,
  },
  {
    id: 5,
    img: Book6,
    tit: `Vol.5 사법 절차에서 아동을 만나는 모든 사람을 위한 핸드북`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/CFJ-handbook/`,
  },
  {
    id: 6,
    img: Book7,
    tit: `Vol.6 법원을 처음 방문한 어린이를 위한 안내서`,
    link: `https://www.unicef.or.kr/data/upload/ebook/crc-publications/CFJ-guide4C/`,
  },
];
const bookData2 = [
  {
    id: 0,
    img: Book20,
    tit: `모든 어린이의 출생이 등록될 권리`,
    link: `773`,
  },
  {
    id: 1,
    img: Book21,
    tit: `보편적 출생신고 브리프`,
    link: `772`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리본부`,
    tel: ``,
    email: `crc@unicef.or.kr`,
  },
];

const BookList = styled.ul`
  align-items: stretch !important;

  & > li {
    width: 25%;
    margin-bottom: 48px;
  }

  .dec-wrap {
    margin-top: 15px;
    padding-left: 26px;

    .link-blank {
      padding-right: 0;
      line-height: 2;
      max-width: 200px;
      &::after {
        vertical-align: middle;
        position: relative;
        display: inline-block;
        right: auto;
        top: auto;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      width: 50%;
    }
  }
  ${breakpoint(`mobile`)} {
    .dec-wrap {
      margin-top: 15px;
      padding-left: 0;
    }
  }
`;
const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 0;
    position: relative;
    z-index: 1;
  }
  .kv-container {
    margin-top: 32px;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 192px;
      height: 192px;
      display: block;
      position: absolute;
      bottom: 0;
      border-radius: 0 100% 0 0;
    }
    &:before {
      right: 100%;
      background: #b2ddf1;
    }
    &:after {
      left: 100%;
      background: #1caae2;
    }

    .dec {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 544px;
      width: 100%;
    }
  }
  ${breakpoint(1160)} {
    .kv-container {
      &:before,
      &:after {
        width: 16.55vw;
        height: 16.55vw;
      }
      .dec {
        max-width: 46.9vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    .kv-container {
      .dec {
        max-width: 100%;
        position: relative;
        margin-bottom: 72px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .kv-container {
      margin-top: 24px;

      &:before,
      &:after {
        display: none;
      }
    }
  }
`;
const SectionCard = styled(Section)`
  ${CardFlex} {
    & > li {
      width: 50%;
    }
  }
  ${breakpoint(`tablet`)} {
    ${CardFlex} {
      & > li {
        width: 100%;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
const SectionFriendly = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }

  ${DlBox} {
    margin-top: 0;
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
    dd {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  ${BookList} {
    margin-top: 63px;
  }
  ${breakpoint(`tablet`)} {
    padding-bottom: 0 !important;
    ${DlBox} {
      dd {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;
const SectionRegister = styled(Section)`
  .blit-list {
    & > li {
      margin-bottom: 16px;
      line-height: 2;
      strong {
        color: #1cabe2;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding-top: 40px !important;
  }
`;
const BookListTitle = styled(H3)`
  font-size: 16px;
  color: #1cabe2;
  margin-top:30px;

  ${breakpoint(`tablet`)} {
    font-size: 14px;
  }
`;

const PublicPrivatePartnership: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A023'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="정책 개선 활동"
      description="for every child, access to justice"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이를 위한 건강한 사회 울타리,{` `}
                  <br className="small-hide" />
                  정책 개선 활동
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <figure className="kv-container">
            <p className="dec">
              <PageComponent id="content1">
                만 18세 미만 모든 어린이는 가정과 사회 속에서 건강하게 성장할 수
                있도록 사회적 보호를 받을 권리가 있습니다. 정부는 이를 위해
                사회안전망, 사법체계, 아동보호시스템을 구축하여 아동을 빈곤과
                사회적 배제로부터 보호해야 합니다. 유니세프한국위원회는
                아동친화적이고 아동의 권리가 존중되는 사법체계를 만들고, 모든
                어린이가 법적 보호를 받으며 동등하게 시작할 수 있도록 관련
                정책·제도개선을 위해 일합니다.
              </PageComponent>
            </p>
            <Image pcSrc={KvImg} mobileSrc={KvImgM} />
          </figure>
        </Container>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <CardFlex className="flex">
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  아동친화사법
                </Tit>
                <p className="dec">
                  아동친화적이고 아동권리가 존중되는 사법체계를 만들기 위해 여러
                  이해관계자들과 함께 고민하고 정책·제도 개선을 위해 힘씁니다.
                  또한 사법체계 하에서 상호작용할 때 어린이가 권리를 보장받을 수
                  있도록 어린이와 관계자들의 인식을 증진시킵니다.
                </p>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  출생등록
                </Tit>
                <p className="dec">
                  태어난 모든 어린이가 법의 보호를 받으며 동등하게 시작할 수
                  있도록 정부 및 시민사회와 함께 보편적 출생등록 시스템 도입을
                  지원합니다. 또한 출생등록을 사회서비스와 연결하는 등 혁신적인
                  해결책을 위해 협력하고 투자합니다.
                </p>
              </div>
            </li>
          </CardFlex>
        </Container>
      </SectionCard>

      <SectionFriendly className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1">아동친화사법</Tit>
            </h2>
            <p className="header-dec">
              아동친화사법은 어린이가 아동권리를 보호받으며 공정하고 시기적절한
              구제 수단을 찾아 적용할 수 있는 사법체계를 의미합니다. 법 앞에서
              인정받을 권리, 법에 대한 동등한 접근, 법 앞의 평등처럼 공정한
              재판을 받을 권리도 포함됩니다. 어린이에게 영향을 미치는 모든 사법
              절차를 다룹니다. 국내 및 국제 수준의 헌법, 형사 및 민사, 공립 및
              사립, 행정 및 군사법을 포함합니다. 관습·종교적 법 절차, 대안적
              분쟁 해결 및 국가인권기관과 같은 준사법적인 메커니즘에도
              적용됩니다. 유니세프한국위원회는 각계 전문가로 구성된 유니세프
              아동친화사법 자문단을 발족하여 모든 어린이가 아동친화적인 사법체계
              속에서 보호받을 수 있도록 발전 방향을 제안하고 있습니다.
            </p>
          </SectionHeader>

          <div className="dl-container">
            <DlBox>
              <dt>
                <Tit size="s4" color="sky">
                  소년사법 관련 유엔아동권리위원회 권고사항 이행 촉구
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  유니세프 아동친화사법 자문단은 유엔아동권리위원회의 지속적인
                  사법 관련 권고사항에 대한 관련 법의 제·개정 및 제도적 개선
                  이행방안을 마련하고, 이를 소년사법 현장에 적용하여 아동친화적
                  사법체계를 구축하고자, 대한민국의 소년사법 현황과 나아갈
                  방향에 대해 논의하였고, 2017년 40개의 이행과제가 담긴{` `}
                  <LinkSafe
                    to={`${process.env.BACKEND_URL}/unicef/api/ebook/view?path=/ebook/crc-publications/732/`}
                    className="link-blank"
                    target="_blank"
                  >
                    ‘아동친화 소년사법 제안서’
                  </LinkSafe>
                  를 발간했습니다.
                </p>
              </dd>
            </DlBox>
            <DlBox>
              <dt>
                <Tit size="s4" color="sky">
                  소년사법 관계자들과 아동권리협약 현장 적용방안 논의
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  2018년 금태섭 의원실과 공동 주최로 ‘아동친화적인 사법체계
                  구축을 위한 심포지엄’을 개최하여 법무부, 지역보호관찰소,
                  소년원 등 관계자와 유니세프아동친화도시 공무원 및 옴부즈퍼슨과
                  함께 아동권리협약을 소년사법 현장에 적용하고 추진하는 방안을
                  논의했습니다.
                </p>
              </dd>
            </DlBox>
            <DlBox>
              <dt>
                <Tit size="s4" color="sky">
                  아동학대와 온라인 성착취 피해아동을 위한 사법체계 구축
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  2019년부터 범죄피해·목격아동을 위한 사법절차와 환경을 보다
                  아동친화적으로 만들기 위해 2기 자문단을 위촉하여, 자문회의 및
                  범죄피해아동과 목격아동을 위한 처우와 제도 개선방안을 제시하고
                  법률 개정활동을 진행했습니다. 코로나 19로 인해 어린이의 온라인
                  사용 시간이 늘고, n번방 사건 등 디지털 환경에서의 아동권리의
                  중요성이 어느때보다 강조되고 있습니다. 이에
                  유니세프한국위원회는 디지털 세상에서 어린이가 안전하고
                  긍정적인 경험을 할 수 있도록 이해관계자들의 아동권리 인식을
                  제고합니다. 사이버불링, 온라인 상의 유해한 콘텐츠로부터 어린이
                  보호, 디지털 정보 활용 능력 교육 촉구, 아동권리 관점의 AI
                  윤리기준 제정 등 디지털 정책 결정의 중심에 아동을 고려할 수
                  있도록 활동합니다.
                </p>
              </dd>
            </DlBox>
            <DlBox>
              <dt>
                <Tit size="s4" color="sky">
                  아동 피해자와 아동 증인에 관한 사법 절차 강화
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  2021년 말, 19세 미만 성폭력 피해자의 진술 영상 녹화물에 대한
                  증거 능력 특례 조항이 위헌이라는 헌법재판소의 2018헌바524
                  결정에 따라 아동 피해자의 심리적 고통 및 2차 피해가 우려되는
                  상황이 초래되었고, 2023년 관련 법 조항이 개정되었음에도
                  불구하고 아직도 여전히 아동 피해자의 충분한 보호와 적절한
                  조치를 위한 노력이 필요한 상태입니다. 유니세프한국위원회는
                  자문단 3기와 함께 아동을 효과적으로 보호하고 아동 피해자와
                  아동 증인에 관련된 국제 기준의 국내 적용을 촉진하기 위하여
                  관련 주요 문서들을 소개합니다.
                </p>
                
                <BookListTitle>[유니세프 아동친화사법 시리즈]</BookListTitle>
                <BookList className="flex">
                  {bookData1.map((row, index) => (
                    <li key={index}>
                      <div className="img-wrap">
                        <Image pcSrc={row.img} mobileSrc={row.img} />
                      </div>
                      <div className="dec-wrap">
                        <LinkSafe
                          to={
                            row.link.startsWith('https')
                              ? row.link
                              : `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYy9hY3RpdmU=&dispFileNm=${row.link}&svrFileNm=${row.link}`
                          }
                          className="link-blank"
                          target={
                            row.link.startsWith('https') ? '_blank' : '_self'
                          }
                        >
                          {row.tit}
                        </LinkSafe>
                      </div>
                    </li>
                  ))}
                </BookList>
              </dd>
            </DlBox>
          </div>
        </Container>
      </SectionFriendly>

      <SectionRegister className="by-sub-main-layout pdt-0">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">출생등록</Tit>
            </h2>
            <p className="header-dec">
              태어났지만 세상에 존재하지 않는 어린이가 있습니다. 출생이 등록되지
              않으면, 법적으로 보호받을 수 없고, 예방접종 등 주요 의료 서비스
              혜택도 받기 힘듭니다. 출생신고 의무자는 부모입니다. 생물학적
              부모가 출생신고를 할 수가 없는 경우에만 출생을 목격한 병원이나
              조산사 등이 제한적으로 공공기관에 출생 사실을 신고할 수 있습니다.
              하지만 출생신고 의무자인 부모의 고의 또는 태만으로 출생신고의 누락
              또는 지연이 발생하거나, 태어나지 않은 아동을 신고하는 경우가
              발생하기도 합니다. 또한, 난민, 난민신청자, 무국적자 등 이주배경
              어린이의 경우, 한국에서 태어났다는 이유로 공적으로 기록되지
              못합니다. 출생신고가 되지 않은 어린이는 예방접종 등의 의료혜택을
              받지 못하거나 영아 유기, 신생아 매매, 불법 입양, 학대 노출 등
              심각한 아동권리 침해에 노출될 수 있습니다.
            </p>
            <p className="header-dec">
              유니세프한국위원회는{` `}
              <LinkSafe
                to="http://www.ubrkorea.org/"
                target="_blank"
                className="link-blank"
              >
                보편적 출생신고 네트워크
              </LinkSafe>
              {` `}
              활동을 통해 한국에서 모든 어린이가 출생과 동시에 등록될 수 있도록
              노력합니다.
            </p>
          </SectionHeader>
          <BookList className="flex">
            {bookData2.map((row, idnex) => (
              <li key={idnex}>
                <div className="img-wrap">
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </div>
                <div className="dec-wrap">
                  <LinkSafe
                    to={`${process.env.BACKEND_URL}/unicef/api/ebook/view?path=/ebook/crc-publications/${row.link}/`}
                    target="_blank"
                    className="link-blank"
                  >
                    {row.tit}
                  </LinkSafe>
                </div>
              </li>
            ))}
          </BookList>
        </Container>
      </SectionRegister>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A023" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          <RelativeBoardCard
            newslist={thumbs}
            isDate={false}
            isSwiper
            overflowHidden
          />
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default PublicPrivatePartnership;
